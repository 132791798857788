import {datadogRum} from '@datadog/browser-rum'

export const ERROR_LOG = {
    POINT_CLAIM: '[Custom Error] Daily Point Claim Error',
    POINT_API_SERVER_FAIL: '[Custom Error] Point API Error',
    POINT_CLAIM_FAIL: '[Custom Error] Point API Response Error',
    REFERRAL_POINT_CLAIM: '[Custom Error] Referral API Connect Point Claim Error',
    FAIL_CHANGE_USER_THUMBNAIL: '[Custom Error] Change User Thumbnail Error',
    FAIL_LOGIN: '[Custom Error] Login Fail Error',
    amplitude: {
        point: {
            error: {
                daily_check: 'account_daily_check_point_claim_failed',
                bonus: 'account_bonus_point_claim_failed',
                mission: 'account_mission_point_claim_failed',
                mission_telegram: 'account_mission_telegram_point_claim_failed',
                mission_x: 'account_mission_x_point_claim_failed',
                mission_referral: 'account_mission_referral_point_claim_failed',
            },
        },
    },
}

export const addErrorLog = (msg: string, option?: object) => {
    datadogRum?.addError(new Error(msg), option)
}
