import React from 'react'
import {ISnackbarAction} from '@store/SnackbarStore'
import TagText from '@component/Text/TagText'
import Text from '@component/Text/Text'

interface IProps {
    text: string
    className?: string
    type?: ISnackbarAction
}

const WizSnackbar: React.FC<IProps> = ({text, type, className}) => {
    return (
        <div
            className={`bg-dark_gray07 dark:bg-dark_gray01 shadow-normal text-center text-white dark:text-bg_dark_gnb text-body1 px-[20px] py-[15px] rounded-[5px] flex items-center gap-x-[20px] ${className}`}>
            <TagText className={'text-dark_gray01 dark:text-gray01'}>{text}</TagText>
            {type?.action && (
                <div
                    onClick={e => {
                        if (e?.cancelable) {
                            e?.preventDefault()
                        }
                        e?.stopPropagation()
                        type?.action()
                    }}>
                    <Text className={'text-ti2 text-dark_primary_shade dark:text-primary_shade cursor-pointer'}>
                        {type?.text || 'Click'}
                    </Text>
                </div>
            )}
        </div>
    )
}

export default WizSnackbar
