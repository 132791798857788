import format from 'date-fns/format'
import {useRouter} from 'next/router'
import {enUS, ko} from 'date-fns/locale'
import {parseISO} from 'date-fns'
import {TIME_DAY, TIME_HOUR, WEEKDAY_KOREAN_LONG, WEEKDAY_LONG} from '@constant/time'
import {useTranslation} from 'next-i18next'

const useFormatDate = () => {
    const language = useRouter().locale
    const {t} = useTranslation()

    const displayDate = (timestamp: number, dateFormat: string) => {
        if (!timestamp) return ''

        return format(timestamp * 1000, dateFormat, {locale: language == 'ko' ? ko : enUS})
    }

    const displayDateMilliSeconds = (timestamp: number, dateFormat: string) => {
        if (!timestamp) return ''

        return format(timestamp, dateFormat, {locale: language == 'ko' ? ko : enUS})
    }

    const displayDateFromText = (dateText: string, dateFormat: string) => {
        if (!dateText) return ''

        return format(Date.parse(dateText), dateFormat, {locale: language == 'ko' ? ko : enUS})
    }

    const getInvoiceDate = (dateText: string) => {
        if (!dateText) return ''
        const day =
            language === 'ko'
                ? WEEKDAY_KOREAN_LONG?.at(new Date(dateText).getDay())
                : WEEKDAY_LONG?.at(new Date(dateText).getDay())

        const time = format(new Date(dateText), 'HH:mm')
        return `${day} ${time}`
    }

    const getUTCTimeStamp = (dateString, locale: string = 'en') => {
        const adjustForUTCOffset = date => {
            return new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate(),
                date.getUTCHours(),
                date.getUTCMinutes(),
                date.getUTCSeconds(),
            )
        }

        const date = parseISO(dateString.toISOString())
        const dateWithOffset = adjustForUTCOffset(date)

        if (locale === 'en') {
            return format(dateWithOffset, 'LLL dd, yyyy HH:mm')
        } else {
            return format(dateWithOffset, 'yyyy.MM.dd HH:mm')
        }
    }

    const formattedUTCOffset = (date: number) => {
        if (!date) {
            return `-`
        }
        const nowDate = date ? new Date(date * 1000) : new Date()
        const utcTimezone = nowDate.getTimezoneOffset() / -60
        const utcTimezoneMin = nowDate.getTimezoneOffset() % -60
        if (utcTimezone < 0) {
            const utcDate = nowDate?.getTime() - TIME_DAY * 1000
            return (
                displayDate(utcDate / 1000, language === 'ko' ? 'yyyy.MM.dd HH:mm' : 'MMM d, yyyy HH:mm') +
                ` (UTC-${Math.floor(Math.abs(utcTimezone))}${utcTimezoneMin ? `:${Math.abs(utcTimezoneMin)}` : ''})`
            )
        } else {
            return (
                displayDate(nowDate?.getTime() / 1000, language === 'ko' ? 'yyyy.MM.dd HH:mm' : 'MMM d, yyyy HH:mm') +
                ` (UTC+${Math.floor(utcTimezone)}${utcTimezoneMin ? `:${Math.abs(utcTimezoneMin)}` : ''})`
            )
        }
    }

    const fromTodayToDate = (date?: string) => {
        const leftTime = (new Date(date)?.getTime() - new Date()?.getTime()) / 1000
        const leftDay = Math.floor(leftTime / TIME_DAY)
        const leftHour = Math.floor(leftTime / TIME_HOUR)
        const leftMinute = Math.ceil((leftTime % TIME_HOUR) / 60)
        return {
            day: leftDay,
            hour: `${leftHour > 9 ? '' : leftHour === 0 ? '' : '0'}${leftHour}`,
            minute: `${leftMinute > 9 ? '' : '0'}${leftMinute}`,
        }
    }

    return {
        displayDate,
        displayDateMilliSeconds,
        displayDateFromText,
        getUTCTimeStamp,
        formattedUTCOffset,
        getInvoiceDate,
        fromTodayToDate,
    }
}

export default useFormatDate
