import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useLoginStore} from '@store/LoginStore'
import {apiGetPoint, TPoint} from '@api/point/point'
import {useRouter} from 'next/router'
import {setUserProperty} from '@util/amplitude'
import usePointSystemStore, {PointSystemStoreTypes} from '@feature/account/pages/point/hooks/usePointSystemStore'

const useQueryGetPoint = (enabled: boolean = false) => {
    const router = useRouter()
    const id = useLoginStore(state => state.id)
    const {level, setLevel} = usePointSystemStore()

    const setDefaultLevel = (data?: TPoint) => {
        const levelOneCompleted =
            data?.missions?.filter(e => PointSystemStoreTypes.LEVEL_ONE === e.level && !e.eligibility.claimed)
                ?.length === 0
        const levelTwoCompleted =
            data?.missions?.filter(e => PointSystemStoreTypes.LEVEL_TWO === e.level && !e.eligibility.claimed)
                ?.length === 0
        const levelThreeCompleted =
            data?.missions?.filter(e => PointSystemStoreTypes.LEVEL_THREE === e.level && !e.eligibility.claimed)
                ?.length === 0

        if (
            level !== PointSystemStoreTypes.LEVEL_FOUR &&
            levelOneCompleted &&
            levelTwoCompleted &&
            levelThreeCompleted
        ) {
            setLevel(PointSystemStoreTypes.LEVEL_FOUR)
        }
        if (level === PointSystemStoreTypes.LEVEL_TWO && levelOneCompleted && levelTwoCompleted) {
            setLevel(PointSystemStoreTypes.LEVEL_THREE)
        }
        if (level === PointSystemStoreTypes.LEVEL_ONE && levelOneCompleted) {
            setLevel(PointSystemStoreTypes.LEVEL_TWO)
        }
    }

    return useQuery({
        queryKey: QueryCacheKeys.point.getPoint(id, router?.locale),
        enabled: enabled && id?.length > 0,
        queryFn: async () => {
            const {data} = await apiGetPoint(router?.locale)
            if (data) {
                setDefaultLevel(data)
                setUserProperty('point', data?.current_point)

                const sortedMissions = data.missions?.sort((a, b) => {
                    return (
                        (a?.eligibility?.claimed ? 1 : 0) - (b?.eligibility?.claimed ? 1 : 0) ||
                        a?.sequence - b?.sequence
                    )
                })
                return {...data, missions: sortedMissions}
            }
            return {} as TPoint
        },
    })
}

export default useQueryGetPoint
