import {MutableRefObject} from 'react'
import {createWithEqualityFn} from 'zustand/traditional'

export const PointSystemStoreTypes = {
    LEVEL_ONE: 1,
    LEVEL_TWO: 2,
    LEVEL_THREE: 3,
    LEVEL_FOUR: 4,
} as const

export type PointSystemStoreType = (typeof PointSystemStoreTypes)[keyof typeof PointSystemStoreTypes]

type PointSystemStoreState = {
    level?: PointSystemStoreType
    point?: number
    successfullyClaimedModalRef?: MutableRefObject<any>
}

type PointSystemStoreAction = {
    setLevel?: (level: PointSystemStoreType) => void
    setPoint?: (point: number) => void
    setSuccessfullyClaimedModalRef?: (modalRef: MutableRefObject<any>) => void
}

const usePointSystemStore = createWithEqualityFn<PointSystemStoreState & PointSystemStoreAction>()((set, get) => {
    return {
        level: PointSystemStoreTypes.LEVEL_ONE,
        setLevel: (level: PointSystemStoreType) => set({level}),
        setPoint: (point: number) => set({point}),
        setSuccessfullyClaimedModalRef: (modalRef: MutableRefObject<any>) =>
            set({successfullyClaimedModalRef: modalRef}),
    }
})

export default usePointSystemStore
