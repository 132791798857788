import {ApiRes} from '@type/api'
import {apiGet, apiPost, IApiGetProps} from '@api/api'
import {IConfirmResponse} from '@api/auths/register/Register'

export type TPoint = {
    current_point: number
    can_receive_count: TCanReceiveCount
    missions: TMission[]
    left_time: string
}
export type TCanReceiveCount = {
    all: number
    level: number[]
}
export type TMission = {
    id: number
    name: string
    description?: string
    error_message?: string
    level: number
    amount: number
    sequence: number
    icon?: string
    is_pinned: boolean
    is_sorted?: boolean
    access_type: TMissionAccessType
    eligibility: TEligibility
}

export type TEligibility = {
    can_receive?: boolean
    claimed?: boolean
    mission_data?: TMissionData & TDailyPoint[]
}

export type TMissionData = {
    total_users?: number
    claimed_users?: number
    unclaimed_users?: number
    user_id?: string
    twitter_id?: string
    twitter_username?: string
    twitter_name?: string
    following_count?: number
    tweet_count?: number
    telegram_id?: number
    username?: string
    count?: number
    total?: number
}

export type TMissionAccessType = 'app' | 'web' | string

export const DailyPointStatus = {
    CAN_NOT_CLAIMED: 'can_not_claimed',
    CLAIMED: 'claimed',
    CAN_CLAIM: 'can_claim',
}
export type TDailyPointStatus = (typeof DailyPointStatus)[keyof typeof DailyPointStatus]
export const DailyPointTypes: Record<number, TDailyPointStatus> = {
    0: DailyPointStatus.CAN_NOT_CLAIMED,
    1: DailyPointStatus.CLAIMED,
    2: DailyPointStatus.CAN_CLAIM,
}
export type TDailyPoint = {
    status?: TDailyPointStatus
    point?: number
    is_today?: boolean
}

export const apiGetPoint = async (language?: string): Promise<ApiRes<TPoint>> => {
    return await apiGet(`point`, {cookies: {language: language}})
}

export const apiPostPoint = async (mission_id: number): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost(`point`, {mission_id})
}

export const apiPostLinkWithX = async (code: string, cookies: IApiGetProps): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost(`point/twitter/link`, {code}, cookies)
}

export const apiPostLinkWithTelegram = async (
    code: string,
    cookies?: IApiGetProps,
): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost(`point/telegram/link`, {code}, cookies)
}
export const MissionKind = {
    DAILY_POINT: 'daily_point',
    CREATE_ACCOUNT: 'create_account',
    FOLLOW_X: 'follow_x',
    JOIN_TELEGRAM: 'join_telegram',
    CONNECT_API_KEY: 'connect_api_key',
    COPY_TRADING: 'copy_trading',
    MONTHLY_LEAGUE: 'monthly_league',
    EVENT_LEAGUE: 'event_league',
    BALANCE_OVER_ONE_K: 'balance_over_1k',
    PAY_INVOICE: 'pay_invoice',
    REFER_A_FRIEND: 'refer_a_friend',
    COPY_LEADER: 'copy_leader',
    REPOST_X: 'repost_x',
    BUY_PRO: 'pro',
    RETAIN_API_ONE_MONTH: 'retain_api_1_month',
    RETAIN_API_THREE_MONTH: 'retain_api_3_month',
    RETAIN_API_SIX_MONTH: 'retain_api_6_month',
    RETAIN_API_TWELVE_MONTH: 'retain_api_12_month',
    BALANCE_OVER_TEN_K: 'balance_over_10k',
    BALANCE_OVER_HUNDRED_K: 'balance_over_100k',
    BALANCE_OVER_ONE_M: 'balance_over_1m',
    PUBLIC_SALE: 'public_sale',
    BONUS_ONE: 'bonus_1',
    BONUS_TWO: 'bonus_2',
    REFERRAL_POINT: 'referral_point',
    WRITE_A_POST_ON_TIMELINE: 'write_a_post_on_timeline',
    LIKE_A_POST_ON_TIMELINE: 'like_a_post_on_timeline',
    UPLOAD_A_PROFILE_PICTURE: 'upload_a_profile_picture',
    SELECT_A_FLAG: 'select_a_flag',
    FIRST_5_FOLLOW: 'first_5_follow',
    FIRST_5_FOLLOWERS: 'first_5_followers',
    FIRST_20_FOLLOWERS: 'first_20_followers',
    FIRST_50_FOLLOWERS: 'first_50_followers',
    FIRST_100_FOLLOWERS: 'first_100_followers',
} as const
export type MissionType = (typeof MissionKind)[keyof typeof MissionKind]
export const MISSION_TYPES: Record<number, MissionType> = {
    1: MissionKind.DAILY_POINT,
    2: MissionKind.CREATE_ACCOUNT,
    3: MissionKind.FOLLOW_X,
    4: MissionKind.JOIN_TELEGRAM,
    5: MissionKind.CONNECT_API_KEY,
    6: MissionKind.COPY_TRADING,
    7: MissionKind.MONTHLY_LEAGUE,
    8: MissionKind.EVENT_LEAGUE,
    9: MissionKind.BALANCE_OVER_ONE_K,
    10: MissionKind.PAY_INVOICE,
    11: MissionKind.REFER_A_FRIEND,
    12: MissionKind.COPY_LEADER,
    13: MissionKind.REPOST_X,
    14: MissionKind.BUY_PRO,
    15: MissionKind.RETAIN_API_ONE_MONTH,
    16: MissionKind.RETAIN_API_THREE_MONTH,
    17: MissionKind.RETAIN_API_SIX_MONTH,
    18: MissionKind.RETAIN_API_TWELVE_MONTH,
    19: MissionKind.BALANCE_OVER_TEN_K,
    20: MissionKind.BALANCE_OVER_HUNDRED_K,
    21: MissionKind.BALANCE_OVER_ONE_M,
    22: MissionKind.PUBLIC_SALE,
    23: MissionKind.BONUS_TWO,
    24: MissionKind.BONUS_ONE,
    25: MissionKind.REFERRAL_POINT,
    26: MissionKind.WRITE_A_POST_ON_TIMELINE,
    27: MissionKind.LIKE_A_POST_ON_TIMELINE,
    28: MissionKind.UPLOAD_A_PROFILE_PICTURE,
    29: MissionKind.SELECT_A_FLAG,
    30: MissionKind.FIRST_5_FOLLOW,
    31: MissionKind.FIRST_5_FOLLOWERS,
    32: MissionKind.FIRST_20_FOLLOWERS,
    33: MissionKind.FIRST_50_FOLLOWERS,
    34: MissionKind.FIRST_100_FOLLOWERS,
}
